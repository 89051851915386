.meeting-operations {
    padding: 2rem;
    background-color: #F9FAFB;
    min-height: 100vh;
}

.meeting-operations__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 16rem;
}

.meeting-operations__loader-icon {
    width: 2rem;
    height: 2rem;
    animation: spin 1s linear infinite;
    color: #6B7280;
}

.meeting-operations__error {
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #FEE2E2;
    color: #DC2626;
    font-size: 0.875rem;
    border: 1px solid #FECACA;
}

.meeting-operations__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.meeting-operations__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
}

.meeting-operations__refresh-btn {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid #E5E7EB;
    border-radius: 0.375rem;
    background-color: white;
    color: #374151;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.meeting-operations__refresh-btn:hover {
    background-color: #F9FAFB;
    border-color: #D1D5DB;
}

.meeting-operations__refresh-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.meeting-operations__group {
    margin-bottom: 2rem;
}

.meeting-operations__group-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
}

.meeting-operations__calendar-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #6B7280;
    margin-right: 0.5rem;
}

.meeting-operations__group-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #111827;
}

.meeting-operations__list {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #E5E7EB;
    overflow: hidden;
}

.meeting-operations__item {
    border-bottom: 1px solid #E5E7EB;
    transition: background-color 0.2s ease;
}

.meeting-operations__item:last-child {
    border-bottom: none;
}

.meeting-operations__item:hover {
    background-color: #F9FAFB;
}

.meeting-operations__item-content {
    padding: 1rem 1.5rem;
}

.meeting-operations__details {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.meeting-operations__timestamp {
    font-size: 0.9375rem;
    color: #111827;
    font-weight: 500;
}

.meeting-operations__status-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
}

.meeting-operations__status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.meeting-operations__status-icon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
}

.meeting-operations__status-icon--processing {
    color: #F59E0B;
    animation: spin 1s linear infinite;
}

.meeting-operations__status-icon--success {
    color: #059669;
}

.meeting-operations__status-icon--error {
    color: #DC2626;
}

.meeting-operations__badge {
    font-weight: 600;
    font-size: 0.875rem;
}

.meeting-operations__status--success {
    background-color: #D1FAE5;
    border: 1px solid #6EE7B7;
}

.meeting-operations__status--success .meeting-operations__badge {
    color: #065F46;
}

.meeting-operations__status--error {
    background-color: #FEE2E2;
    border: 1px solid #FECACA;
}

.meeting-operations__status--error .meeting-operations__badge {
    color: #B91C1C;
}

.meeting-operations__status--processing {
    background-color: #FEF3C7;
    border: 1px solid #FCD34D;
}

.meeting-operations__status--processing .meeting-operations__badge {
    color: #92400E;
}

.meeting-operations__info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 0.25rem;
}

.meeting-operations__info-icon {
    width: 0.875rem;
    height: 0.875rem;
    color: #6B7280;
    flex-shrink: 0;
}

.meeting-operations__info-text {
    font-size: 0.75rem;
    color: #6B7280;
    line-height: 1.25;
}

.meeting-operations__empty {
    padding: 3rem;
    text-align: center;
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid #E5E7EB;
}

.meeting-operations__empty-message {
    color: #6B7280;
    font-size: 0.875rem;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 640px) {
    .meeting-operations {
        padding: 1rem;
    }

    .meeting-operations__header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .meeting-operations__refresh-btn {
        width: 100%;
        justify-content: center;
    }

    .meeting-operations__item-content {
        padding: 1rem;
    }

    .meeting-operations__group-header {
        padding: 0;
    }

    .meeting-operations__empty {
        padding: 2rem 1rem;
    }
}