.content-type-selector {
  display: flex;
  gap: 1rem;
  padding: 1rem 2rem;
  background-color: var(--content-bg-color);
  border-bottom: 1px solid var(--border-color);
}

.content-type-selector__button {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  background: none;
  color: var(--text-color);
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

.content-type-selector__button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.content-type-selector__button--active {
  background-color: var(--meeting-list-accent-color);
  color: white;
}

.content-type-selector__button--active:hover {
  background-color: var(--meeting-list-accent-color-hover);
} 