.team-selector {
    width: 100%;
    margin-bottom: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.team-selector__header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
}

.team-selector__icon {
    width: 18px;
    height: 18px;
    color: #6c5ce7;
}

.team-selector__label {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
}

.team-selector__container {
    position: relative;
    margin-bottom: 10px;
}

.team-selector__trigger {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
    transition: all 0.3s;
}

.team-selector__trigger:hover {
    border-color: #6c5ce7;
}

.team-selector__trigger-text {
    margin-right: 8px;
}

.team-selector__dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.team-selector__search {
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
}

.team-selector__search-input {
    width: 100%;
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
}

.team-selector__search-input:focus {
    border-color: #6c5ce7;
}

.team-selector__options {
    max-height: 200px;
    overflow-y: auto;
}

.team-selector__option {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    font-size: 14px;
    color: #000000;
    background: transparent;
    border: none;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s;
}

.team-selector__option:hover {
    background: #f8f8f8;
}

.team-selector__option--selected {
    background: #f0f0f0;
}

.team-selector__check {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: #6c5ce7;
}

.team-selector__check--hidden {
    opacity: 0;
}

.team-selector__empty {
    padding: 12px;
    text-align: center;
    color: #666666;
    font-size: 14px;
}

.team-selector__badges {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 8px;
}

.team-selector__badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 4px 10px;
    background: #f0f0f0;
    border-radius: 16px;
    font-size: 12px;
    color: #000000;
}

.team-selector__badge-remove {
    background: none;
    border: none;
    padding: 0;
    color: #666666;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
}

.team-selector__badge-remove:hover {
    color: #000000;
}

/* Keeping the existing responsive adjustments */
@media (max-width: 768px) {
    .upload {
        padding: 30px 20px;
    }

    .upload__title {
        font-size: 24px;
    }

    .upload__subtitle {
        font-size: 16px;
    }

    .upload__teams-title {
        font-size: 16px;
    }

    .upload__dropzone {
        padding: 40px 20px;
    }

    .upload__dropzone-text {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .upload-container {
        padding: 20px 10px;
    }

    .upload {
        padding: 20px 15px;
    }

    .team-selector__badge {
        padding: 4px 8px;
        font-size: 12px;
    }
}