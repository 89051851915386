/* CreateTeamModal.css */

* {
  box-sizing: border-box;
}

.create-team-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-team-modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.create-team-modal__container {
  position: relative;
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.create-team-modal__close-btn {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  background: transparent;
  border: none;
  color: #6b7280;
  cursor: pointer;
  transition: color 0.3s ease;
}

.create-team-modal__close-btn:hover {
  color: #111827;
}

.create-team-modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #111827;
  text-align: center;
}

.create-team-modal__form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.create-team-modal__form-group {
  margin-bottom: 1.5rem;
  width: 100%;
}

.create-team-modal__input {
  width: 100%;
  padding: 0.875rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: #f9fafb;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.create-team-modal__input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.1);
}

.create-team-modal__form-group--member {
  position: relative;
}

.create-team-modal__add-member-btn {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #4CAF50;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;
}

.create-team-modal__add-member-btn:hover {
  color: #388E3C;
}

.create-team-modal__members-preview {
  margin-top: 2rem;
}

.create-team-modal__members-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #374151;
}

.create-team-modal__members-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: center;
}

.create-team-modal__member {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.create-team-modal__member-avatar {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #a3e635;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.create-team-modal__member-email {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #111827;
  text-align: center;
  max-width: 5rem;
  word-wrap: break-word;
}

.create-team-modal__remove-member-btn {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
  color: #6b7280;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.create-team-modal__remove-member-btn:hover {
  background-color: #e5e7eb;
  color: #374151;
}

.create-team-modal__submit-btn {
  margin-top: 2.5rem;
  width: 100%;
  background-color: #4CAF50;
  color: #ffffff;
  padding: 0.875rem;
  border-radius: 9999px;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-team-modal__submit-btn:hover {
  background-color: #388E3C;
  transform: translateY(-2px);
}

.create-team-modal__submit-icon {
  margin-right: 0.75rem;
}

.create-team-modal__search-results {
  position: absolute;
  background-color: white;
  border: 1px solid #e5e7eb;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 100;
}

.create-team-modal__search-result-item {
  padding: 0.5rem;
  cursor: pointer;
}

.create-team-modal__search-result-item:hover {
  background-color: #f3f4f6;
}


@media (max-width: 640px) {
  .create-team-modal__container {
    padding: 2rem;
  }

  .create-team-modal__title {
    font-size: 1.25rem;
  }

  .create-team-modal__input {
    font-size: 0.875rem;
  }

  .create-team-modal__member-avatar {
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
  }
}

.create-team-modal__container {
  position: relative;
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 2rem;  /* Increase the border-radius to make it more rounded */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

/* Close button (X) - Center the X inside the button */
.create-team-modal__close-btn {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  background: transparent;
  border: none;
  color: #6b7280;
  cursor: pointer;
  font-size: 1.5rem; /* Adjust font size if needed */
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure the "X" is centered */
  width: 40px; /* Define width and height to ensure it's square */
  height: 40px;
  border-radius: 50%; /* Make the button itself circular */
  transition: color 0.3s ease, background-color 0.3s ease;
}

.create-team-modal__close-btn:hover {
  color: #111827;
  background-color: #f3f4f6; /* Add a hover background effect */
}

/* Optionally increase modal roundness on smaller screens */
@media (max-width: 640px) {
  .create-team-modal__container {
    padding: 2rem;
    border-radius: 2.5rem; /* More rounded on smaller screens */
  }
}
