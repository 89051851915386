.chat-widget {
    height: 480px;
    width: 100%;
    border-radius: 1rem;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
    padding: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}
  
  .chat-widget__overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), transparent);
    backdrop-filter: blur(5px);
  }
  
  .chat-widget__content {
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .chat-widget__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .chat-widget__title {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 1rem;
  }
  
  .chat-widget__status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .chat-widget__status-dot {
    height: 0.5rem;
    width: 0.5rem;
    background: #10B981;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(16, 185, 129, 0.2);
    animation: pulse 2s infinite;
  }
  
  .chat-widget__status-text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
  }
  
  .chat-widget__search {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .chat-widget__search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.4);
    height: 1.25rem;
    width: 1.25rem;
  }
  
  .chat-widget__search-input {
    width: 100%;
    padding: 1rem 3rem 1rem 3rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.9);
    font-size: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
  }
  
  .chat-widget__search-input:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 12px -1px rgba(0, 0, 0, 0.15);
  }
  
  .chat-widget__cursor {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    height: 1rem;
    width: 2px;
    background: rgba(0, 0, 0, 0.6);
    animation: blink 1s infinite;
  }
  
  .chat-widget__loader {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    flex: 1;
  }
  
  .chat-widget__loader-rings {
    position: relative;
    height: 3rem;
    width: 3rem;
  }
  
  .chat-widget__loader-ring {
    position: absolute;
    border-radius: 50%;
    border: 2px solid transparent;
  }
  
  .chat-widget__loader-ring--outer {
    width: 3rem;
    height: 3rem;
    border-top-color: rgba(0, 0, 0, 0.6);
    animation: spin 1s linear infinite;
  }
  
  .chat-widget__loader-ring--inner {
    width: 2rem;
    height: 2rem;
    border-top-color: rgba(0, 0, 0, 0.4);
    animation: spin-reverse 0.8s linear infinite;
    top: 0.5rem;
    left: 0.5rem;
  }
  
  .chat-widget__response {
    animation: fadeIn 0.3s ease-out;
    flex: 1;
    overflow-y: auto;
    padding-right: 0.5rem;
    margin-top: 1rem; /* Added spacing between question and response */
}
  
  .chat-widget .chat-widget__response .chat-widget__response-code {
    background-color: #f5f5f5;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
    font-size: 0.9rem;
    color: #333;
    margin: 0.75rem 0; /* Increased spacing around code blocks */
    overflow-x: auto;
    height: fit-content;
    min-height: 0;
    display: block;
}

.chat-widget__response-content {
    height: fit-content;
    min-height: 0;
    padding-top: 0.5rem; /* Added padding at the top of response content */
}

.chat-widget__response-line {
    height: fit-content;
    min-height: 0;
    margin-bottom: 0.75rem; /* Added spacing between response lines */
}
  
  /* Optional: Style inline code within text */
  .chat-widget__response-line code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  }
  
  .chat-widget__footer {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    min-height: 1.5rem;
    background: rgba(255, 255, 255, 0.8);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
  
  .chat-widget__footer-text {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
  }
  
  .chat-widget__footer-status {
    color: rgba(0, 0, 0, 0.6);
    width: 80px;
    text-align: right;
  }
  
  /* Custom Scrollbar */
  .chat-widget__response::-webkit-scrollbar {
    width: 6px;
  }
  
  .chat-widget__response::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .chat-widget__response::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  /* Animations */
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  @keyframes spin-reverse {
    to { transform: rotate(-360deg); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .chat-widget {
      padding: 1.5rem;
    }
  
    .chat-widget__search-input {
      font-size: 0.9rem;
    }
  
    .chat-widget__response-content {
      padding: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .chat-widget {
      padding: 1rem;
    }
  
    .chat-widget__footer {
      left: 1rem;
      right: 1rem;
      font-size: 0.75rem;
    }
  }
  