@import url('../../fonts.css');

body, html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    background-color: #f8f9fa;
    height: 100%;
}

.landing-page {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    background-color: rgba(13, 28, 51, 0.8); /* Slightly lighter than #0a192f with transparency */
    background: rgba(10, 25, 47, 0.9); 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
}

.logo-landing {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
}

.nav-menu {
    display: flex;
    gap: 30px; /* Increased gap to spread out items */
    align-items: center;
}

.nav-item {
    color: #fff;
    text-decoration: none;
    font-size: 16px; /* Updated font size */
    font-weight: 500;
}

/* Desktop auth buttons */
.auth-buttons.desktop {
    display: flex;
    gap: 20px;
    align-items: center;
}

/* Mobile auth buttons - hidden by default */
.auth-buttons.mobile {
    display: none;
}

.login-button {
    color: #fff;
    text-decoration: none;
    font-size: 16px; /* Updated font size */
    font-weight: 500;
}

.free-trial-button {
    background-color: #0066FF;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px; /* Updated font size */
    font-weight: 500;
}

.landing-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.text-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

h1 {
    font-size: 64px;
    color: #fff;
    margin-bottom: 30px;
    line-height: 1.1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-weight: 800;
}

p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

.highlight {
    color: #FF1493;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.gradient-circle {
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 50%;
}

.gradient-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app-stores {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}

.app-store-badge {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #000000;
}

.app-store-badge img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

/* LandingPage.css */
.widget-container {
    width: 480px;
    height: 480px;
    flex-shrink: 0;
   }
   
   @media (max-width: 1024px) {
    .widget-container {
      width: 420px;
      height: 420px;
    }
   }
   
   @media (max-width: 768px) {
    .content {
      flex-direction: column;
      text-align: center;
      gap: 40px;
    }
   
    .widget-container {
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
    }
   
    .text-content {
      padding-right: 0;
      max-width: 100%;
    }
   }
   
   @media (max-width: 480px) {
    .widget-container {
      height: 420px;
    }
   }

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        padding: 20px;
    }

    .nav-menu {
        margin: 20px 0;
    }

    .content {
        flex-direction: column;
    }

    .text-content {
        padding-right: 0;
        padding-bottom: 40px;
        text-align: center;
    }

    .gradient-circle {
        width: 300px;
        height: 300px;
    }

    h1 {
        font-size: 36px;
    }

    p {
        font-size: 16px;
    }

    .app-stores {
        justify-content: center;
    }

    .auth-buttons.desktop {
        display: none;
    }

    .auth-buttons.mobile {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
}

.living-text {
    background: linear-gradient(120deg, #00F5FF 0%, #9E7BFF 100%);  /* Bright cyan to purple */
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
    position: relative;
    -webkit-animation: livingPulse 4s ease-in-out infinite;
    animation: livingPulse 4s ease-in-out infinite;
    will-change: opacity;
    text-shadow: 0 0 10px rgba(0, 245, 255, 0.3),
                 0 0 20px rgba(158, 123, 255, 0.2);
}

.living-text::after {
    content: '';
    position: absolute;
    left: -5%;
    bottom: -3px;
    width: 110%;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(0, 245, 255, 0.5), transparent);
    opacity: 0.8;
    -webkit-animation: livingLine 4s ease-in-out infinite;
    animation: livingLine 4s ease-in-out infinite;
    will-change: opacity;
    filter: blur(0.5px);
}

@-webkit-keyframes livingPulse {
    0%, 100% {
        opacity: 1;
        text-shadow: 0 0 10px rgba(0, 245, 255, 0.3),
                     0 0 20px rgba(158, 123, 255, 0.2);
    }
    50% {
        opacity: 0.9;
        text-shadow: 0 0 15px rgba(0, 245, 255, 0.4),
                     0 0 30px rgba(158, 123, 255, 0.3);
    }
}

@keyframes livingPulse {
    0%, 100% {
        opacity: 1;
        text-shadow: 0 0 10px rgba(0, 245, 255, 0.3),
                     0 0 20px rgba(158, 123, 255, 0.2);
    }
    50% {
        opacity: 0.9;
        text-shadow: 0 0 15px rgba(0, 245, 255, 0.4),
                     0 0 30px rgba(158, 123, 255, 0.3);
    }
}

@-webkit-keyframes livingLine {
    0%, 100% {
        transform: scaleX(0.7);
        opacity: 0.5;
    }
    50% {
        transform: scaleX(1);
        opacity: 0.8;
    }
}

@keyframes livingLine {
    0%, 100% {
        transform: scaleX(0.7);
        opacity: 0.5;
    }
    50% {
        transform: scaleX(1);
        opacity: 0.8;
    }
}

.pricing-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: auto;
  gap: 10px;
}

.pricing-strike {
  position: relative;
  color: #fff;
  text-decoration: none;
  opacity: 0.7;
  display: inline-block;
  padding: 0 5px;
  width: auto;
  white-space: nowrap;
}

.pricing-strike::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent, 
    rgba(255, 255, 255, 0.8) 20%, 
    rgba(255, 255, 255, 0.8) 80%, 
    transparent
  );
  transform: translateY(-50%) rotate(-8deg);
  animation: strikeThrough 0.3s ease-out forwards;
}

.free-label {
  position: absolute;
  bottom: -15px;
  right: -10px;
  background: linear-gradient(135deg, #00F5FF 0%, #9E7BFF 100%);
  color: white;
  font-size: 0.7rem;
  padding: 2px 6px;
  border-radius: 4px;
  white-space: nowrap;
  transform: rotate(-3deg);
  animation: popIn 0.3s ease-out forwards;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes strikeThrough {
  from {
    transform: translateY(-50%) scaleX(0);
  }
  to {
    transform: translateY(-50%) scaleX(1);
  }
}

@keyframes popIn {
  from {
    transform: rotate(-3deg) scale(0);
    opacity: 0;
  }
  to {
    transform: rotate(-3deg) scale(1);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .pricing-wrapper {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: fit-content;
  }
  
  .pricing-strike {
    padding: 0 2px;
    font-size: 16px;
  }

  .free-label {
    position: relative;
    bottom: 0;
    right: 0;
    margin-left: 8px;
    display: inline-block;
  }

  .auth-buttons.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    width: 100%;
  }

  .auth-buttons.mobile .login-button,
  .auth-buttons.mobile .free-trial-button {
    display: block;
    width: 100%;
    text-align: center;
    padding: 12px 20px;
  }

  .auth-buttons.mobile .free-trial-button {
    margin-top: 5px;
  }
}

/* How It Works Section */
.how-it-works-section {
  background: linear-gradient(180deg, #0a192f 0%, #0d1b34 100%);
  padding: 120px 0;
  position: relative;
  overflow: hidden;
}

.section-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  z-index: 2;
}

.section-title {
  color: #fff;
  font-size: clamp(2rem, 4vw, 3rem);
  text-align: center;
  margin-bottom: 80px;
  font-weight: 700;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  margin-bottom: 80px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.step-icon-wrapper {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #00F5FF 0%, #9E7BFF 100%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  transition: transform 0.3s ease;
}

.step:hover .step-icon-wrapper {
  transform: scale(1.1);
}

.step-icon {
  width: 40px;
  height: 40px;
  color: white;
}

.step h3 {
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 16px;
  font-weight: 600;
}

.step p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.6;
}

/* Demo Section */
.demo-section {
  margin-top: 100px;
  text-align: center;
}

.demo-section h3 {
  color: #fff;
  font-size: 1.75rem;
  margin-bottom: 40px;
  font-weight: 600;
}

.video-container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
}

.video-container video {
  width: 100%;
  display: block;
  border-radius: 16px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(135deg, #00F5FF 0%, #9E7BFF 100%);
  border: none;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.play-button:hover {
  transform: translate(-50%, -50%) scale(1.1);
  box-shadow: 0 0 30px rgba(0, 245, 255, 0.3);
}

.play-button svg {
  color: white;
  margin-left: 4px; /* Optical adjustment for play icon */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .how-it-works-section {
    padding: 80px 0;
  }

  .section-title {
    margin-bottom: 40px;
  }

  .steps-container {
    gap: 20px;
  }

  .step {
    padding: 20px;
  }

  .demo-section {
    margin-top: 60px;
  }
}

.knowledge-base-section {
  padding: 120px 0;
  background: linear-gradient(180deg, #0d1b34 0%, #0a192f 100%);
  position: relative;
  overflow: hidden;
}

.kb-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.kb-text {
  animation: fadeInSlideUp 0.8s ease-out forwards;
  padding-right: 2rem;
  max-width: 540px;
  margin: 0 auto;
  position: relative;
}

.text-wrapper {
  max-width: 100%;
  position: relative;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  border: 1px solid rgba(0, 245, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 245, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.text-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(0, 245, 255, 0.15);
}

.text-wrapper::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(45deg, rgba(0, 245, 255, 0.1), rgba(158, 123, 255, 0.1));
  border-radius: 25px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.text-wrapper:hover::before {
  opacity: 1;
}

.highlight-word {
  color: #00F5FF;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.highlight-word::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #00F5FF, #9E7BFF);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.text-wrapper:hover .highlight-word::after {
  transform: scaleX(1);
}

.section-title {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.section-title::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #00F5FF, #9E7BFF);
  border-radius: 2px;
}

.feature-points {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
}

.feature-point {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.02);
  transition: background 0.3s ease;
}

.feature-point:hover {
  background: rgba(255, 255, 255, 0.05);
}

.feature-icon {
  width: 24px;
  height: 24px;
  color: #00F5FF;
}

.primary-text {
  font-size: 1.25rem;
  line-height: 1.7;
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.9);
  animation: fadeInSlideUp 0.8s ease-out 0.2s forwards;
  opacity: 0;
  transform: translateY(20px);
}

.secondary-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  animation: fadeInSlideUp 0.8s ease-out 0.4s forwards;
  opacity: 0;
  transform: translateY(20px);
}

.brain-visualization {
  height: 800px;  /* Made bigger */
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brain-container {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: floatAnimation 6s ease-in-out infinite;  /* Slowed down from 3s to 6s */
}

.ai-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.brainContainer {
  width: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

/* Add animations for brain paths */
.animatePaths {
  animation: drawBrain 4s forwards ease-out;  /* Slowed down from 2s to 4s */
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  filter: drop-shadow(0 0 15px rgba(0, 245, 255, 0.3));  /* Reduced glow intensity */
}

.animateRects, .animateCircles {
  animation: fadeIn 2s forwards;  /* Slowed down from 1s to 2s */
  filter: drop-shadow(0 0 8px rgba(158, 123, 255, 0.3));  /* Reduced glow intensity */
}

.brain-svg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.brain-path {
  fill: none;
  stroke: url(#brain-gradient);
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: drawBrain 3s forwards ease-out;
  filter: drop-shadow(0 0 10px rgba(0, 245, 255, 0.3));
}

.brain-fold {
  fill: none;
  stroke: rgba(0, 245, 255, 0.3);
  stroke-width: 1;
  opacity: 0;
  animation: foldAppear 2s forwards ease-out;
}

.synapse {
  position: absolute;
  width: 6px;
  height: 6px;
  background: linear-gradient(135deg, #00F5FF, #9E7BFF);
  border-radius: 50%;
  z-index: 3;
}

.pulse-ring {
  position: absolute;
  inset: -4px;
  border: 2px solid rgba(0, 245, 255, 0.5);
  border-radius: 50%;
  animation: synapseGlow 2s infinite;
}

.thought-particles {
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, rgba(0, 245, 255, 0.1) 0%, transparent 70%);
  filter: blur(20px);
  animation: thoughtFlow 10s infinite linear;
}

@keyframes drawBrain {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes foldAppear {
  from {
    opacity: 0;
    stroke-dashoffset: 100;
  }
  to {
    opacity: 0.6;
    stroke-dashoffset: 0;
  }
}

@keyframes synapseGlow {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes thoughtFlow {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floatAnimation {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes glowPulse {
  0%, 100% { box-shadow: 0 0 20px rgba(0, 245, 255, 0.3); }
  50% { box-shadow: 0 0 30px rgba(158, 123, 255, 0.5); }
}

@keyframes expandPulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes connectionPulse {
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

@keyframes particleFloat {
  0% { background-position: 0 0; }
  100% { background-position: 100px 100px; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 1200px) {
  .kb-content {
    gap: 40px;
  }
  
  .section-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .kb-content {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 40px;
  }

  .kb-text {
    padding-right: 0;
    max-width: 100%;
  }

  .text-wrapper {
    max-width: 540px;
    margin: 0 auto;
  }

  .brain-visualization {
    height: 500px;
  }
}

.animated-brain {
  animation: floatAnimation 3s ease-in-out infinite, glowPulse 2s ease-in-out infinite;
}

.ai-avatar {
  width: 200px;
  height: 200px;
  position: relative;
  z-index: 2;
  animation: floatAnimation 3s ease-in-out infinite;
}

.ai-head {
  fill: none;
  stroke: #00F5FF;
  stroke-width: 2;
  filter: drop-shadow(0 0 10px rgba(0, 245, 255, 0.3));
}

.ai-eye {
  fill: #00F5FF;
  filter: drop-shadow(0 0 5px rgba(0, 245, 255, 0.5));
  animation: glowPulse 2s ease-in-out infinite;
}

.ai-smile {
  stroke: #00F5FF;
  stroke-width: 2;
  stroke-linecap: round;
  filter: drop-shadow(0 0 5px rgba(0, 245, 255, 0.3));
}

.ai-circuit {
  stroke: #00F5FF;
  stroke-width: 1;
  stroke-linecap: round;
  opacity: 0.6;
  filter: drop-shadow(0 0 5px rgba(0, 245, 255, 0.3));
  animation: circuitPulse 3s infinite;
}

.ai-node {
  fill: #00F5FF;
  filter: drop-shadow(0 0 5px rgba(0, 245, 255, 0.5));
  animation: glowPulse 2s infinite alternate;
}

.glow-effect {
  position: absolute;
  width: 250px;
  height: 250px;
  background: radial-gradient(circle at center, rgba(0, 245, 255, 0.1) 0%, transparent 70%);
  filter: blur(20px);
  animation: glowRotate 10s linear infinite;
}

@keyframes circuitPulse {
  0%, 100% {
    opacity: 0.3;
    stroke-width: 2;
  }
  50% {
    opacity: 0.8;
    stroke-width: 3;
  }
}

@keyframes glowRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  background: linear-gradient(180deg, #0a192f 0%, #0d1b34 100%);
  padding: 80px 0 20px;
  color: #fff;
  position: relative;
  z-index: 2;
  margin-top: 200px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.footer-left {
  flex: 1;
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #00F5FF, #9E7BFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-tagline {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 20px;
}

.footer-links {
  display: flex;
  gap: 80px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-column h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.footer-column a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-column a:hover {
  color: #00F5FF;
}

.footer-bottom {
  max-width: 1200px;
  margin: 60px auto 0;
  padding: 20px 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.footer-bottom p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-links {
    gap: 40px;
  }
  
  .footer-left {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer-links {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }
}

/* Mobile menu button styles */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: fixed;
  right: 20px;
  top: 20px;
  transform: none;
  z-index: 2002;
}

.hamburger {
  width: 24px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* Mobile menu styles */
@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
    position: fixed;
    right: 20px;
    top: 20px;
    transform: none;
    z-index: 2002;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background: rgba(10, 25, 47, 0.98);
    backdrop-filter: blur(10px);
    padding: 80px 20px 20px;
    flex-direction: column;
    gap: 20px;
    transition: right 0.3s ease;
    z-index: 2001;
  }

  .nav-menu.mobile-open {
    right: 0;
  }

  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
  }

  .nav-menu .nav-item,
  .nav-menu .auth-buttons.mobile {
    display: block;
    width: fit-content;
  }

  .auth-buttons.desktop {
    display: none;
  }

  .auth-buttons.mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .nav-item {
    padding: 10px 0;
    width: 100%;
    text-align: left;
    font-size: 18px;
    color: #fff;
  }

  .pricing-wrapper {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .free-label {
    position: static;
    transform: none;
  }
}

