/* Overall Admin Team Management container */
.admin-team-management {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: white;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

/* Header section */
.admin-team-management__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.admin-team-management__header-left,
.admin-team-management__header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Teams count in header */
.admin-team-management__member-count {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4361ee;
  background-color: #eef0ff;
  padding: 0.375rem 0.75rem;
  border-radius: 8px;
}

/* Create Team button */
.admin-team-management__create-team-btn {
  display: flex;
  align-items: center;
  background-color: #4361ee;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
  box-shadow: 0 1px 2px rgba(67, 97, 238, 0.1);
}

.admin-team-management__create-team-btn svg {
  margin-right: 0.5rem;
}

.admin-team-management__invite-btn svg {
  margin-right: 0.5rem;
}

.admin-team-management__create-team-btn:hover {
  background-color: #3451db;
  transform: translateY(-1px);
}

/* Search bar container */
.admin-team-management__search-container {
  position: relative;
  display: flex;
  align-items: center;
}

/* Search input field */
.admin-team-management__search-input {
  padding: 0.625rem 0.75rem 0.625rem 2.5rem;
  width: 18rem;
  border: 1px solid #e2e8f0;
  background-color: white;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.admin-team-management__search-input:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.12);
}

/* Search icon */
.admin-team-management__search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  height: 1.25rem;
  width: 1.25rem;
  color: #94a3b8;
}

/* Invite button */
.admin-team-management__invite-btn {
  display: flex;
  align-items: center;
  background-color: white;
  color: #4361ee;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.admin-team-management__invite-btn:hover {
  background-color: #f8fafc;
  border-color: #4361ee;
}

/* Settings button */
.admin-team-management__settings-btn {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #64748b;
  border-radius: 8px;
  transition: all 0.2s ease;
  background-color: white;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.admin-team-management__settings-btn:hover {
  background-color: #f8fafc;
  color: #475569;
}

/* Teams list container */
.admin-team-management__team-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Team item */
.admin-team-management__team-item {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.admin-team-management__team-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

/* Team header */
.admin-team-management__team-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.admin-team-management__team-header-left {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.admin-team-management__team-name {
  font-size: 0.9375rem;
  font-weight: 500;
  color: #1e293b;
}

/* Members list */
.admin-team-management__member-list {
  padding: 0.5rem 1rem 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Member item */
.admin-team-management__member-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: #f8fafc;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  gap: 1rem;
}

.admin-team-management__member-item:hover {
  background-color: #f1f5f9;
}

/* Member avatar */
.admin-team-management__member-avatar {
  width: 2.25rem;
  height: 2.25rem;
  min-width: 2.25rem;
  background-color: #4361ee;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 1rem;
}

/* Member info */
.admin-team-management__member-info {
  flex-grow: 1;
  min-width: 0;
}

.admin-team-management__member-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e293b;
}

.admin-team-management__member-role {
  color: #64748b;
  font-weight: normal;
  font-size: 0.8125rem;
}

.admin-team-management__member-email {
  font-size: 0.8125rem;
  color: #64748b;
  margin-top: 0.125rem;
}

/* Menu buttons (three dots) */
.admin-team-management__menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 6px;
  color: #64748b;
  transition: all 0.2s ease;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.admin-team-management__menu-btn:hover {
  background-color: #f1f5f9;
  color: #475569;
}

.admin-team-management__team-actions {
  position: relative; /* Set relative positioning on the container */
}

.admin-team-management__member-actions {
  position: relative; /* Set relative positioning on the container */
}

/* Dropdown menu */
.admin-team-management__menu {
  position: absolute;
  top: calc(100% + 4px); /* Add a small gap below the button */
  right: 0; /* Aligns the menu to the right of its container */
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 10px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
}

.admin-team-management__menu-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;
  font-size: 0.875rem;
  color: #475569;
  transition: all 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;
}

.admin-team-management__menu-item:hover {
  background-color: #f8fafc;
  color: #1e293b;
}

/* Loading and error states */
.admin-team-management__loading {
  text-align: center;
  padding: 2rem;
  color: #64748b;
}

.admin-team-management__error {
  text-align: center;
  padding: 2rem;
  color: #ef4444;
}