/* AdminCompanyManagement.css */

/* Overall Admin Company Management container */
.admin-company-management {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: #ffffff;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }
  
  /* Header section */
  .admin-company-management__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  /* Tab section */
  .admin-company-management__tabs {
    display: flex;
    gap: 1rem;
  }
  
  /* Single tab */
  .admin-company-management__tab {
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    color: #6b7280;
    background-color: #f3f4f6;
    border: none;
    cursor: pointer;
  }
  
  .admin-company-management__tab.active {
    background-color: #ece9fd;
    color: #4a4de5;
  }
  
  /* Actions section (search and invite buttons) */
  .admin-company-management__actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  /* Search bar container */
  .admin-company-management__search-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  /* Search input */
  .admin-company-management__search-input {
    padding: 0.5rem 2rem 0.5rem 2.5rem;
    width: 16rem;
    border: 1px solid #e5e7eb;
    background-color: #f3f4f6;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  .admin-company-management__search-input:focus {
    outline: none;
    border-color: #5e5eff;
    background-color: white;
  }
  
  /* Search icon inside input */
  .admin-company-management__search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 1.25rem;
    width: 1.25rem;
    color: #9ca3af;
  }
  
  /* Invite button */
  .admin-company-management__invite-btn {
    display: flex;
    align-items: center;
    background-color: #007aff;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
  }
  
  .admin-company-management__invite-btn:hover {
    background-color: #0056b3;
  }
  
  /* Icon inside button */
  .admin-company-management__icon {
    margin-right: 0.5rem;
  }
  
  /* User List container */
  .admin-company-management__user-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Single user item */
  .admin-company-management__user-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out;
  }
  
  /* Avatar styling (round background) */
  .admin-company-management__avatar {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #a3e635; /* Lighter green */
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 1.125rem;
    margin-right: 1rem;
  }
  
  /* User info (name and email) */
  .admin-company-management__user-info {
    flex-grow: 1;
  }
  
  /* User name */
  .admin-company-management__name {
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
  }
  
  /* User role */
  .admin-company-management__role {
    color: #6b7280;
    font-weight: normal;
  }
  
  /* Email */
  .admin-company-management__email {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  /* Three-dot menu for user actions */
.admin-company-management__user-actions {
    position: relative;
  }
  
  .admin-company-management__menu-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .admin-company-management__menu {
    position: absolute;
    right: 0;
    top: 2rem; /* Position below the button */
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    z-index: 10;
    padding: 0.5rem 0;
    min-width: 8rem; /* Minimum width for menu */
  }
  
  .admin-company-management__menu-item {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: #111827;
    font-size: 0.875rem;
  }
  
  .admin-company-management__menu-item:hover {
    background-color: #f3f4f6;
  }
  