/* src/components/Upload/Upload.css */
@import url('../../fonts.css');

.upload-container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 1rem;
}

.upload {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    background-color: #ffffff;
    color: #000000;
    padding: 1.5rem; /* Reduced from 3rem to 2rem */
    padding-top: 0.5rem;
    text-align: center;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    border-radius: 15px;
}

/* Rest of the CSS stays the same */
.upload__title {
    font-size: clamp(24px, 4vw, 32px);
    margin-bottom: clamp(10px, 2vw, 15px);
    color: #000000;
    font-weight: bold;
    line-height: 1.2;
}

.upload__subtitle {
    font-size: clamp(14px, 2.5vw, 18px);
    color: #666666;
    margin-bottom: clamp(20px, 4vw, 40px);
    line-height: 1.5;
}

.upload__teams-section {
    margin-bottom: clamp(20px, 4vw, 40px);
    text-align: left;
}

.upload__teams-title {
    font-size: clamp(16px, 2.5vw, 18px);
    color: #000000;
    margin-bottom: clamp(10px, 2vw, 20px);
    font-weight: 500;
}

.upload__dropzone {
    border: 3px dashed #e0e0e0;
    border-radius: 12px;
    padding: clamp(30px, 5vw, 60px) clamp(15px, 3vw, 30px);
    cursor: pointer;
    transition: border-color 0.3s;
    background-color: #ffffff;
    margin-bottom: clamp(15px, 3vw, 30px);
}

.upload__dropzone--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.upload__dropzone:hover:not(.upload__dropzone--disabled) {
    border-color: #6c5ce7;
}

.upload__icon {
    font-size: clamp(24px, 4vw, 36px);
    margin-bottom: clamp(10px, 2vw, 20px);
    color: #6c5ce7;
}

.upload__dropzone-text {
    color: #666666;
    font-size: clamp(14px, 2.5vw, 18px);
    line-height: 1.5;
}

.upload__file-input {
    display: none;
}

.upload__progress {
    width: 100%;
    height: clamp(8px, 1.5vw, 12px);
    background-color: #e0e0e0;
    border-radius: 6px;
    margin-top: clamp(15px, 3vw, 25px);
    position: relative;
    overflow: hidden;
}

.upload__progress-bar {
    height: 100%;
    background-color: #6c5ce7;
    transition: width 0.5s ease-in-out;
}

.upload__progress-text {
    display: block;
    text-align: center;
    margin-top: clamp(10px, 2vw, 15px);
    color: #666666;
    font-size: clamp(14px, 2vw, 1.1em);
}

.upload__status {
    margin-top: clamp(20px, 3vw, 30px);
    padding: clamp(10px, 2vw, 15px);
    border-radius: 8px;
    background-color: #f0f0f0;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-size: clamp(14px, 2vw, 1.1em);
}

/* Media Queries */
@media screen and (max-width: 640px) {
    .upload-container {
        padding: 0.5rem;
    }

    .upload {
        padding: 1.5rem; /* Even smaller padding for mobile */
        border-radius: 8px;
    }

    .upload__dropzone {
        border-width: 2px;
    }
}

@media (hover: hover) {
    .upload__dropzone:hover:not(.upload__dropzone--disabled) {
        border-color: #6c5ce7;
        background-color: rgba(108, 92, 231, 0.02);
    }
}

/* Progress Notification Styles */
.upload-notification {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 300px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(150%);
    transition: all 0.3s ease;
    z-index: 1000;
}

.upload-notification--visible {
    transform: translateY(0);
}

.upload-notification--success {
    background: #10B981;
    color: white;
}

.upload-notification--success .upload-notification__title {
    color: white;
}

.upload-notification--success .upload-notification__progress-bar {
    background: white;
}

.upload-notification--success .upload-notification__progress-text {
    color: white;
}

.upload-notification__content {
    padding: 16px;
}

.upload-notification__header {
    margin-bottom: 12px;
}

.upload-notification__title {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.upload-notification__success-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: white;
    color: #10B981;
    border-radius: 50%;
    font-size: 12px;
    animation: scaleIn 0.2s ease;
}

.upload-notification__progress-container {
    background: #f0f0f0;
    border-radius: 4px;
    height: 4px;
    position: relative;
    margin-right: 40px;
}

.upload-notification__progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #2563eb;
    border-radius: 4px;
    transition: width 0.3s ease;
}

.upload-notification__progress-text {
    position: absolute;
    right: -35px;
    top: -7px;
    font-size: 12px;
    color: #666;
    min-width: 33px;
}

@keyframes scaleIn {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}