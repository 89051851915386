:root {
    --background-color: #f8f9fa;
    --text-color: #202124;
    --sidebar-bg-color: #ffffff;
    --content-bg-color: #ffffff;
    --shadow-color: rgba(0, 0, 0, 0.1);
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
  }
  
  .container {
    display: flex;
    height: 100vh;
    width: 100%;
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* padding: 0 20px;  */
    background-color: var(--background-color);
}

  
  .content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--content-bg-color);
    border-radius: 8px;
    box-shadow: 0 2px 10px var(--shadow-color);
    padding: 24px;
    overflow-y: auto;  /* Enable vertical scrolling */
    margin: 1rem;
}

  
  .summary-page {
    width: 100%;
    align-self: flex-start;
  }
  
  .meeting-list {
    width: 100%;
    padding: 20px;
    background-color: var(--content-bg-color);
    border-radius: 8px;
    /* box-shadow: 0 2px 5px var(--shadow-color); */
  }