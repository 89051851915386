/* TeamPage.css */

/* Overall Team Management container */
.team-page {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: #ffffff;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }
  
  /* Team name header */
  .team-page__team-name {
    margin-bottom: 1rem;
  }
  
  .team-page__team-name h1 {
    font-size: 1.5rem;
    color: #111827; /* Change color to black */
    font-weight: 700;
    margin-left: 0; /* Align it to the left */
  }
  
  /* Header section */
  .team-page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  /* Tab section */
  .team-page__tabs {
    display: flex;
    gap: 1rem;
  }
  
  /* Single tab */
  .team-page__tab {
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    color: #6b7280;
    background-color: #f3f4f6;
    border: none;
    cursor: pointer;
  }
  
  .team-page__tab.active {
    background-color: #ece9fd;
    color: #4a4de5;
  }
  
  /* Actions section (search input) */
  .team-page__actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  /* Search bar container */
  .team-page__search-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  /* Search input */
  .team-page__search-input {
    padding: 0.5rem 2rem 0.5rem 2.5rem;
    width: 16rem;
    border: 1px solid #e5e7eb;
    background-color: #f3f4f6;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  .team-page__search-input:focus {
    outline: none;
    border-color: #5e5eff;
    background-color: white;
  }
  
  /* Search icon inside input */
  .team-page__search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 1.25rem;
    width: 1.25rem;
    color: #9ca3af;
  }
  
  /* Team Member List container */
  .team-page__member-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Single team member item */
  .team-page__member-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out;
  }
  
  /* Avatar styling (round background) */
  .team-page__avatar {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #a3e635; /* Light green */
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 1.125rem;
    margin-right: 1rem;
  }
  
  /* Team member info (name and email) */
  .team-page__member-info {
    flex-grow: 1;
  }
  
  /* Team member name */
  .team-page__name {
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
  }
  
  /* User role */
  .team-page__role {
    color: #6b7280;
    font-weight: normal;
  }
  
  /* Email */
  .team-page__email {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  /* Error and Loading states */
  .team-page__loading,
  .team-page__error,
  .team-page__no-team {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    padding: 20px;
  }
  