:root {
  --signup-bg: #F8F9FD;
  --signup-surface: #FFFFFF;
  --signup-text-primary: #1A1A1A;
  --signup-text-secondary: #6B7280;
  --signup-accent: #4466EE;
  --signup-accent-hover: #3355DD;
  --signup-border: #E5E7EB;
  --signup-error: #DC2626;
}

.signup {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  position: relative;
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  background: rgba(10, 25, 47, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.signup__main {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: relative;
  z-index: 2;
}

.signup__container {
  width: 100%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.signup__title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--signup-text-primary);
  margin-bottom: 0.5rem;
  text-align: center;
}

.signup__subtitle {
  color: var(--signup-text-secondary);
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.signup__oauth-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.signup__oauth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem;
  border: 1px solid var(--signup-border);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.95);
  color: var(--signup-text-primary);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.signup__oauth-button:hover {
  background-color: rgba(255, 255, 255, 1);
  border-color: var(--signup-accent);
}

.signup__divider {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.signup__divider::before,
.signup__divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--signup-border);
}

.signup__divider-text {
  padding: 0 0.75rem;
  color: var(--signup-text-secondary);
  font-size: 0.75rem;
}

.signup__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.signup__input {
  padding: 0.75rem;
  border: 1px solid var(--signup-border);
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9);
}

.signup__input:focus {
  outline: none;
  border-color: var(--signup-accent);
  box-shadow: 0 0 0 2px rgba(68, 102, 238, 0.1);
  background-color: rgba(255, 255, 255, 1);
}

.signup__input.error {
  border-color: var(--signup-error);
}

.error-message {
  color: var(--signup-error);
  font-size: 0.75rem;
  margin-top: -0.5rem;
}

.signup__submit {
  padding: 0.75rem;
  background-color: var(--signup-accent);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.signup__submit:hover {
  background-color: var(--signup-accent-hover);
}

.signup__links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.signup__link {
  color: var(--signup-text-secondary);
  font-size: 0.75rem;
  text-decoration: none;
  transition: color 0.2s ease;
}

.signup__link:hover {
  color: var(--signup-text-primary);
}

.signup__link-separator {
  color: var(--signup-text-secondary);
  font-size: 0.75rem;
}

.signup__trial-note {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  color: var(--signup-text-secondary);
}

.signup__login-link {
  color: var(--signup-accent);
  text-decoration: none;
  cursor: pointer;
}

.signup__login-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 20px;
  }

  .nav-menu {
    margin: 20px 0;
  }

  .signup__main {
    padding: 1rem;
  }

  .signup__container {
    padding: 1.5rem;
  }
}