/* src/components/ProtectedRoute/ProtectedRoute.css */

/* Flexbox layout for Sidebar and MainContent */
.protected-layout {
    display: flex;              /* This creates the flexbox layout */
    height: 100vh;              /* Makes the layout take up full viewport height */
  }
  
  .protected-layout > * {
    flex-grow: 1;               /* Make each child (Sidebar and MainContent) grow to fit the space */
  }
  
  .protected-layout > .sidebar {
    flex: 0 0 250px;            
    /* background-color: #ffffff;   */
  }
  
  .protected-layout > .main-content {
    flex-grow: 1;               /* MainContent will take the remaining space */
    overflow: auto;             /* Allow scrolling if content overflows */
  }
  