.document-short-summary {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 2.5rem;
}

.document-short-summary__card {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
}

.document-short-summary__metadata {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  color: var(--text-secondary);
}

.document-short-summary__section {
  padding: 1.5rem 0;
  border-top: 1px solid var(--border-color);
}

.document-short-summary__section:first-of-type {
  border-top: none;
  padding-top: 0;
}

.document-short-summary__section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

.document-short-summary__text {
  color: var(--text-secondary);
  line-height: 1.6;
  white-space: pre-wrap;
}

.document-short-summary__note {
  margin-bottom: 1.5rem;
}

.document-short-summary__note:last-child {
  margin-bottom: 0;
}

.document-short-summary__note strong {
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 600;
  flex-shrink: 0;
}

.document-short-summary__note-point {
  font-size: 0.875rem;
  color: var(--text-secondary);
  flex: 1;
}

.document-short-summary__subtitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--brief-summary-text-primary);
  margin-bottom: 0.5rem;
}

.document-short-summary__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.document-short-summary__list-item {
  color: var(--brief-summary-text-secondary);
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
}

.document-short-summary__list-item:last-child {
  margin-bottom: 0;
}

.document-short-summary__list-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--brief-summary-accent);
}

.document-short-summary__loading,
.document-short-summary__error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1rem;
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .document-short-summary {
    padding: 1rem;
  }

  .document-short-summary__card {
    padding: 1rem;
  }

  .document-short-summary__section-title {
    font-size: 1.125rem;
  }
} 