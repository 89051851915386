.document-detailed-summary {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.document-detailed-summary__card {
  background: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.document-detailed-summary__metadata {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.document-detailed-summary__section {
  margin-bottom: 2rem;
}

.document-detailed-summary__section:last-child {
  margin-bottom: 0;
}

.document-detailed-summary__section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.document-detailed-summary__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.document-detailed-summary__topic {
  padding: 1rem;
  background: var(--background-secondary);
  border-radius: 6px;
}

.document-detailed-summary__subtitle {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.75rem;
}

.document-detailed-summary__details {
  color: var(--text-secondary);
  line-height: 1.6;
  white-space: pre-wrap;
}

.document-detailed-summary__loading {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
}

.document-detailed-summary__error {
  text-align: center;
  padding: 2rem;
  color: var(--error);
}

@media (max-width: 768px) {
  .document-detailed-summary {
    padding: 1rem;
  }

  .document-detailed-summary__card {
    padding: 1rem;
  }

  .document-detailed-summary__section-title {
    font-size: 1.25rem;
  }

  .document-detailed-summary__subtitle {
    font-size: 1rem;
  }
} 