/* src/index.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  background-color: #f5f5f7;
  color: #1d1d1f;
}
.container {
  display: flex;
  height: 100vh;
}
