.data-sources-section {
    min-height: 100vh;
    width: 100%;
    position: relative;
    background-color: #0a192f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
}

.animation-heading {
    text-align: center;
    padding: clamp(2rem, 5vh, 4rem) 0 clamp(4rem, 8vh, 8rem);
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.animation-heading h2 {
    color: #fff;
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: clamp(0.5rem, 2vh, 1rem);
    font-weight: 700;
}

.animation-heading p {
    color: #8892b0;
    font-size: clamp(1rem, 1.5vw, 1.25rem);
    max-width: 40rem;
    margin: 0 auto;
    line-height: 1.6;
    padding: 0 1rem;
}

.animation-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.platform-icon-container {
    width: clamp(3rem, 5vw, 5rem);
    height: clamp(3rem, 5vw, 5rem);
    position: relative;
    z-index: 10;
}

.icon-background {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: clamp(0.5rem, 1vw, 1rem);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.icon-svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.inveris-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.inveris-logo h1 {
    color: #fff;
    font-size: clamp(2rem, 4vw, 3.5rem);
    font-weight: 700;
    /* letter-spacing: 0.1em; */
    /* text-transform: uppercase; */
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.absolute {
    position: absolute;
}

@keyframes float {
    0%, 100% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
}

@keyframes pulse {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

/* Media queries */
@media (max-width: 1200px) {
    .animation-container {
        height: 600px;
        transform: scale(0.9);
    }
}

@media (max-width: 900px) {
    .data-sources-section {
        padding: 3rem 0;
    }
    
    .animation-container {
        height: 500px;
        transform: scale(0.8);
    }
    
    .animation-heading h2 {
        font-size: 1.5rem;
    }
    
    .animation-heading p {
        font-size: 1rem;
    }
}

@media (max-width: 600px) {
    .data-sources-section {
        padding: 2rem 0;
        min-height: 80vh;
    }
    
    .animation-container {
        height: 400px;
        transform: scale(0.6);
    }
    
    .animation-heading h2 {
        font-size: 1.5rem;
    }
    
    .animation-heading p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .animation-container {
        height: 300px;
        transform: scale(0.5);
    }
}