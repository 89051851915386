:root {
    --meeting-list-bg-color: #F8F9FD;
    --meeting-list-surface-color: #FFFFFF;
    --meeting-list-text-primary: #1A1A1A;
    --meeting-list-text-secondary: #6B7280;
    --meeting-list-accent-color: #4466EE;
    --meeting-list-accent-color-hover: #3355DD;
    --meeting-list-border-color: #E5E7EB;
    --meeting-list-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .meeting-list {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    padding: 2rem 2.5rem;
    min-height: 100vh;
  }
  
  .meeting-list__header {
    margin-bottom: 2rem;
  }
  
  .meeting-list__title {
    font-size: 1.875rem;
    font-weight: 600;
    color: var(--meeting-list-text-primary);
    margin: 0;
  }
  
  .meeting-list__container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .meeting-list__group {
    margin-bottom: 2rem;
  }
  
  .meeting-list__group-header {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: var(--meeting-list-text-secondary);
    margin-bottom: 1rem;
    font-weight: 500;
    border-bottom: 1px solid var(--meeting-list-border-color);
    padding-bottom: 0.5rem;
  }
  
  .meeting-list__card {
    background-color: var(--meeting-list-surface-color);
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease;
    border: 1px solid var(--meeting-list-border-color);
  }
  
  .meeting-list__card:hover {
    box-shadow: var(--meeting-list-shadow);
  }
  
  .meeting-list__card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .meeting-list__title-wrapper {
    flex-grow: 1;
  }
  
  .meeting-list__meeting-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.25rem 0;
    color: var(--meeting-list-text-primary);
  }
  
  .meeting-list__meeting-date {
    color: var(--meeting-list-text-secondary);
    font-size: 0.875rem;
  }
  
  .meeting-list__content {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem;
    background-color: var(--meeting-list-bg-color);
    border-radius: 8px;
  }
  
  .meeting-list__note {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0.25rem 0;
  }
  
  .meeting-list__note strong {
    color: var(--meeting-list-text-primary);
    font-size: 0.875rem;
    font-weight: 600;
    flex-shrink: 0;
  }
  
  .meeting-list__note-point {
    font-size: 0.875rem;
    color: var(--meeting-list-text-secondary);
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .meeting-list__buttons {
    display: flex;
    gap: 0.75rem;
  }
  
  .meeting-list__button {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: 500;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    white-space: nowrap;
    cursor: pointer;
  }
  
  .meeting-list__button--summary {
    background-color: var(--meeting-list-accent-color);
    color: white;
    border: none;
  }
  
  .meeting-list__button--summary:hover {
    background-color: var(--meeting-list-accent-color-hover);
  }
  
  .meeting-list__button--detailed {
    background-color: transparent;
    color: var(--meeting-list-accent-color);
    border: 1px solid currentColor;
  }
  
  .meeting-list__button--detailed:hover {
    background-color: rgba(68, 102, 238, 0.1);
  }
  
  .meeting-list__empty {
    text-align: center;
    padding: 3rem;
    color: var(--meeting-list-text-secondary);
    background: var(--meeting-list-surface-color);
    border-radius: 12px;
    border: 1px solid var(--meeting-list-border-color);
  }
  
  @media (max-width: 1024px) {
    .meeting-list__container {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .meeting-list {
      padding: 1.5rem;
    }
  
    .meeting-list__card {
      padding: 1rem;
    }
  
    .meeting-list__card-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  
    .meeting-list__note {
      flex-direction: column;
      gap: 0.25rem;
    }
  
    .meeting-list__note strong {
      min-width: unset;
    }
  
    .meeting-list__buttons {
      width: 100%;
    }
  
    .meeting-list__button {
      flex: 1;
      text-align: center;
      justify-content: center;
    }
  }
  
  @media (max-width: 640px) {
    .meeting-list {
      padding: 1rem;
    }
  
    .meeting-list__title {
      font-size: 1.5rem;
    }
  }