:root {
  --inveris-bg: #ffffff;
  --inveris-surface: #ffffff;
  --inveris-text-primary: #252A3D;
  --inveris-text-secondary: #6B7280;
  --inveris-accent: #4171FF;
  --inveris-accent-hover: #2563EB;
  --inveris-border: #E5E7EB;
  --inveris-shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --inveris-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: var(--inveris-surface);
  color: var(--inveris-text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.welcome-message {
  text-align: center;
  margin-bottom: 2.5rem;
}

.welcome-message h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--inveris-text-primary);
  font-weight: 500;
}

.welcome-message h2 {
  font-size: 1.25rem;
  color: var(--inveris-text-secondary);
  margin-bottom: 2.5rem;
  font-weight: 400;
}

.example-queries {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-width: 640px;
  margin: 0 auto;
}

.example-query {
  background-color: var(--inveris-bg);
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--inveris-border);
  box-shadow: var(--inveris-shadow-sm);
}

.example-query:hover {
  box-shadow: var(--inveris-shadow);
  border-color: var(--inveris-accent);
  transform: translateY(-1px);
}

.example-query span {
  font-size: 1.5rem;
  margin-right: 0.75rem;
}

.example-query p {
  margin: 0;
  font-size: 0.875rem;
  color: var(--inveris-text-primary);
}

.message {
  max-width: 70%;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  line-height: 1.5;
  font-size: 1rem;
}

.message-content {
  line-height: 1.6;
  color: var(--inveris-text-primary);
}

.message-content p {
  margin: 0.75rem 0;
  font-size: 1rem;
  color: var(--inveris-text-primary);
}

.message-content p:first-child {
  margin-top: 0;
}

.message-content p:last-child {
  margin-bottom: 0;
}

.message-content ul,
.message-content ol {
  margin: 0.75rem 0;
  padding-left: 1.5rem;
}

.message-content li {
  margin: 0.5rem 0;
}

.message-content strong {
  font-weight: 600;
}

.message-content em {
  font-style: italic;
}

.message-content code {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  font-size: 0.9em;
}

.message-content pre {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.75rem 0;
  overflow-x: auto;
}

.message-content pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

/* Adjust colors for dark message backgrounds */
.message.user .message-content,
.message.user .message-content p,
.message.user .message-content li {
  color: white;
}

.message.user .message-content code,
.message.user .message-content pre {
  background-color: rgba(255, 255, 255, 0.1);
}

.user {
  align-self: flex-end;
  background-color: var(--inveris-accent);
  color: white;
}

.assistant {
  align-self: flex-start;
  background-color: var(--inveris-bg);
  color: var(--inveris-text-primary);
  border: 1px solid var(--inveris-border);
  box-shadow: var(--inveris-shadow-sm);
}

.chat-input-container {
  display: flex;
  padding: 1rem 2rem;
  background-color: var(--inveris-bg);
  border-top: 1px solid var(--inveris-border);
}

.chat-input {
  flex-grow: 1;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  border: 1px solid var(--inveris-border);
  border-radius: 0.5rem;
  background-color: var(--inveris-surface);
  color: var(--inveris-text-primary);
  outline: none;
  transition: all 0.2s ease;
}

.chat-input:focus {
  border-color: var(--inveris-accent);
  box-shadow: 0 0 0 2px rgba(65, 113, 255, 0.1);
}

.chat-input::placeholder {
  color: var(--inveris-text-secondary);
}

.chat-send-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

.chat-send-button:hover {
  background-color: var(--inveris-surface);
}

.send-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--inveris-accent);
  transition: color 0.2s ease;
}

.send-icon:hover {
  color: var(--inveris-accent-hover);
}

.thinking-dots {
  display: inline-flex;
  gap: 4px;
  margin-left: 8px;
  align-items: center;
}

.thinking-dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--inveris-text-primary);
  opacity: 0.6;
  animation: chatgptThinking 1.4s infinite;
}

.thinking-dot:nth-child(1) {
  animation-delay: 0s;
}

.thinking-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.thinking-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes chatgptThinking {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-4px);
  }
}

/* When inside a user message, make dots white */
.message.user .thinking-dots .thinking-dot {
  background-color: white;
}