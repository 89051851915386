:root {
  --brief-summary-text-primary: #1A1A1A;
  --brief-summary-text-secondary: #6B7280;
  --brief-summary-bg-color: #F8F9FD;
  --brief-summary-accent: #4466EE;
  --brief-summary-border: #E5E7EB;
  --brief-summary-card-bg: #FFFFFF;
}

.brief-summary {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 2.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.brief-summary__card {
  background-color: var(--brief-summary-card-bg);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid var(--brief-summary-border);
}

.brief-summary__metadata {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  color: var(--brief-summary-text-secondary);
}

.brief-summary__section {
  padding: 1.5rem 0;
  border-top: 1px solid var(--brief-summary-border);
}

.brief-summary__section:first-of-type {
  border-top: none;
  padding-top: 0;
}

.brief-summary__section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--brief-summary-text-primary);
  margin-bottom: 1rem;
}

.brief-summary__text {
  color: var(--brief-summary-text-secondary);
  font-size: 1rem;
  line-height: 1.6;
}

.brief-summary__note {
  margin-bottom: 1.5rem;
}

.brief-summary__note:last-child {
  margin-bottom: 0;
}

.brief-summary__subtitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--brief-summary-text-primary);
  margin-bottom: 0.5rem;
}

.brief-summary__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.brief-summary__list-item {
  color: var(--brief-summary-text-secondary);
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
}

.brief-summary__list-item:last-child {
  margin-bottom: 0;
}

.brief-summary__list-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--brief-summary-accent);
}

.brief-summary__assigned {
  color: var(--brief-summary-accent);
  font-weight: 500;
}

.brief-summary__loading,
.brief-summary__error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1rem;
  color: var(--brief-summary-text-secondary);
}

@media (max-width: 768px) {
  .brief-summary {
    padding: 1rem;
  }

  .brief-summary__card {
    padding: 1rem;
  }

  .brief-summary__metadata {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .brief-summary__metadata span:nth-child(2) {
    display: none;
  }

  .brief-summary__section {
    padding: 1rem 0;
  }
}