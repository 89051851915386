:root {
  --top-bar-bg-color: #FFFFFF;
  --text-color: #1A1A1A;
  --text-secondary: #6B7280;
  --accent-color: #4466EE;
  --accent-hover: #3355DD;
  --search-bg: #F8F9FD;
  --border-color: #E5E7EB;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

/* Top Bar Block */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: var(--top-bar-bg-color);
  border-bottom: 1px solid var(--border-color);
}

/* Left Section */
.top-bar__left {
  display: flex;
  align-items: center;
}

.top-bar__home-button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.top-bar__home-button:hover {
  background-color: var(--search-bg);
}

/* Search Section */
.top-bar__search {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 600px;
  background-color: var(--search-bg);
  padding: 10px 16px;
  border-radius: 8px;
  margin: 0 24px;
  border: 1px solid var(--border-color);
  transition: border-color 0.2s;
}

.top-bar__search:focus-within {
  border-color: var(--accent-color);
}

.top-bar__search-icon-wrapper {
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar__search-icon {
  color: var(--text-secondary);
  font-size: 1rem;
}

.top-bar__search-input {
  border: none;
  background: none;
  flex-grow: 1;
  margin-left: 12px;
  font-size: 0.875rem;
  color: var(--text-color);
  width: 100%;
  outline: none;
}

.top-bar__search-input::placeholder {
  color: var(--text-secondary);
}

.top-bar__send-icon-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  transition: color 0.2s;
}

.top-bar__send-icon {
  color: var(--text-secondary);
  font-size: 1rem;
}

.top-bar__send-icon-wrapper:hover .top-bar__send-icon {
  color: var(--accent-color);
}

/* Right Section */
.top-bar__actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.top-bar__invite-button {
  display: flex;
  align-items: center;
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.875rem;
  font-weight: 500;
}

.top-bar__invite-button-icon {
  margin-right: 8px;
  font-size: 1.1rem;
}

.top-bar__invite-button:hover {
  background-color: var(--accent-hover);
}

/* Profile Section */
.top-bar__profile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-secondary);
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.top-bar__profile:hover {
  background-color: var(--search-bg);
}

.top-bar__profile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background-color: var(--top-bar-bg-color);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  min-width: 180px;
  overflow: hidden;
  z-index: 1000;
}

.top-bar__menu-item {
  display: block;
  padding: 10px 16px;
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.top-bar__menu-item:hover {
  background-color: var(--search-bg);
}

/* Modal Styles */
.top-bar-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.top-bar-modal__content {
  background: var(--top-bar-bg-color);
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.top-bar-modal__header {
  margin-bottom: 24px;
}

.top-bar-modal__title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color);
}

.top-bar-modal__body {
  margin-bottom: 24px;
}

.top-bar-modal__input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 0.875rem;
  transition: border-color 0.2s;
}

.top-bar-modal__input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.top-bar-modal__footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.top-bar-modal__button {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.top-bar-modal__button--primary {
  background-color: var(--accent-color);
  color: white;
  border: none;
}

.top-bar-modal__button--primary:hover {
  background-color: var(--accent-hover);
}

.top-bar-modal__button--secondary {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.top-bar-modal__button--secondary:hover {
  background-color: var(--search-bg);
}

/* Modal Overlay */
.ant-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: var(--top-bar-bg-color);
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 24px; /* This creates a consistent vertical gap */
}



.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color);
}

/* Modal Body */
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Use gap for spacing between elements in body */
  /* Removed margin-bottom; vertical spacing is handled by .modal-content's gap */
}

/* Inputs */
.modal-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 0.875rem;
  transition: border-color 0.2s;
  margin-top: 0.3rem;
}

.modal-input:focus {
  outline: none;
  border-color: var(--accent-color);
}

/* Footer and Buttons */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px; /* gap for spacing between buttons */
}

.modal-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-button-primary {
  background-color: var(--accent-color);
  color: white;
  border: none;
}

.modal-button-primary:hover {
  background-color: var(--accent-hover);
}

.modal-button-secondary {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.modal-button-secondary:hover {
  background-color: var(--search-bg);
}

.modal-input-teams .team-selector{
  margin-top: 0.3rem;
}