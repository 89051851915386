/* Glare Family */
@font-face {
    font-family: 'PPFragment Glare';
    src: url('./assets/fonts/PPFragment-GlareLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PPFragment Glare';
    src: url('./assets/fonts/PPFragment-GlareRegular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PPFragment Glare';
    src: url('./assets/fonts/PPFragment-GlareExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  
  /* Sans Family */
  @font-face {
    font-family: 'PPFragment Sans';
    src: url('./assets/fonts/PPFragment-SansLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PPFragment Sans';
    src: url('./assets/fonts/PPFragment-SansRegular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PPFragment Sans';
    src: url('./assets/fonts/PPFragment-SansExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  
  /* Serif Family */
  @font-face {
    font-family: 'PPFragment Serif';
    src: url('./assets/fonts/PPFragment-SerifLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PPFragment Serif';
    src: url('./assets/fonts/PPFragment-SerifRegular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PPFragment Serif';
    src: url('./assets/fonts/PPFragment-SerifExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  
  /* Text Family */
  @font-face {
    font-family: 'PPFragment Text';
    src: url('./assets/fonts/PPFragment-TextRegular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  