:root {
    --signin-bg: #F8F9FD;
    --signin-surface: #FFFFFF;
    --signin-text-primary: #1A1A1A;
    --signin-text-secondary: #6B7280;
    --signin-accent: #4466EE;
    --signin-accent-hover: #3355DD;
    --signin-border: #E5E7EB;
    --signin-error: #DC2626;
  }
  
  .signin {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    position: relative;
    z-index: 2;
  }
  
  .signin__header {
    padding: 1.5rem 2rem;
    background-color: transparent;
    position: relative;
    z-index: 2;
  }
  
  .signin__logo {
    height: 40px;
    width: auto;
  }
  
  .signin__main {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    position: relative;
    z-index: 2;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    background: rgba(10, 25, 47, 0.9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    min-width: 1.5rem;
  }

  .logo-landing {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }
  
  .signin__container {
    width: 100%;
    max-width: 400px;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .signin__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--signin-text-primary);
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .signin__subtitle {
    color: var(--signin-text-secondary);
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .signin__oauth-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
  }

  .signin__account-prompt {
    text-align: center;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--signin-border);
    color: var(--signin-text-secondary);
    font-size: 0.875rem;
  }
  
  .signin__signup-link {
    color: var(--signin-accent);
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.2s ease;
  }
  
  .signin__signup-link:hover {
    color: var(--signin-accent-hover);
    text-decoration: underline;
  }
  
  
  .signin__oauth-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border: 1px solid var(--signin-border);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.95);
    color: var(--signin-text-primary);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .signin__oauth-button:hover {
    background-color: rgba(255, 255, 255, 1);
    border-color: var(--signin-accent);
  }
  
  .signin__divider {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
  }
  
  .signin__divider::before,
  .signin__divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid var(--signin-border);
  }
  
  .signin__divider-text {
    padding: 0 0.75rem;
    color: var(--signin-text-secondary);
    font-size: 0.75rem;
  }
  
  .signin__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .signin__input {
    padding: 0.75rem;
    border: 1px solid var(--signin-border);
    border-radius: 8px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .signin__input:focus {
    outline: none;
    border-color: var(--signin-accent);
    box-shadow: 0 0 0 2px rgba(68, 102, 238, 0.1);
    background-color: rgba(255, 255, 255, 1);
  }
  
  .signin__input.error {
    border-color: var(--signin-error);
  }
  
  .error-message {
    color: var(--signin-error);
    font-size: 0.75rem;
    margin-top: -0.5rem;
  }
  
  .signin__submit {
    padding: 0.75rem;
    background-color: var(--signin-accent);
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .signin__submit:hover {
    background-color: var(--signin-accent-hover);
  }
  
  .signin__links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
  }
  
  .signin__link {
    color: var(--signin-text-secondary);
    font-size: 0.75rem;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .signin__link:hover {
    color: var(--signin-text-primary);
  }
  
  .signin__link-separator {
    color: var(--signin-text-secondary);
    font-size: 0.75rem;
  }
  
  .signin__trial-note {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: var(--signin-text-secondary);
  }
  
  @media (max-width: 640px) {
    .signin__main {
      padding: 1rem;
    }
  
    .signin__container {
      padding: 1.5rem;
    }
  
    .signin__header {
      padding: 1rem;
    }
  }