:root {
  --document-list-bg-color: #F8F9FD;
  --document-list-surface-color: #FFFFFF;
  --document-list-text-primary: #1A1A1A;
  --document-list-text-secondary: #6B7280;
  --document-list-accent-color: #4466EE;
  --document-list-accent-color-hover: #3355DD;
  --document-list-border-color: #E5E7EB;
  --document-list-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.document-list {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  padding: 2rem 2.5rem;
  min-height: 100vh;
}

.document-list__header {
  margin-bottom: 2rem;
}

.document-list__title {
  font-size: 1.875rem;
  font-weight: 600;
  color: var(--document-list-text-primary);
  margin: 0;
}

.document-list__container {
  max-width: 1200px;
  margin: 0 auto;
}

.document-list__group {
  margin-bottom: 2rem;
}

.document-list__group-header {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--document-list-text-secondary);
  margin-bottom: 1rem;
  font-weight: 500;
  border-bottom: 1px solid var(--document-list-border-color);
  padding-bottom: 0.5rem;
}

.document-list__card {
  position: relative;
  padding: 1.5rem;
  background: var(--card-background);
  border-radius: 8px;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
}

.document-list__card:hover {
  box-shadow: var(--document-list-shadow);
}

.document-list__card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.document-list__title-wrapper {
  flex-grow: 1;
}

.document-list__document-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  color: var(--document-list-text-primary);
}

.document-list__document-type {
  color: var(--document-list-text-secondary);
  font-size: 0.875rem;
}

.document-list__content {
  margin-top: 0.2rem;
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  background-color: var(--meeting-list-bg-color);
  border-radius: 8px;
}

.document-list__note {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.25rem 0;
}

.document-list__note strong {
  color: var(--document-list-text-primary);
  font-size: 0.875rem;
  font-weight: 600;
  flex-shrink: 0;
}

.document-list__note-point {
  font-size: 0.875rem;
  color: var(--document-list-text-secondary);
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-list__description {
  margin: 0;
  font-size: 0.875rem;
  color: var(--document-list-text-secondary);
  line-height: 1.5;
}

.document-list__button-container {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.document-list__buttons {
  display: flex;
  gap: 0.75rem;
}

.document-list__button {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  white-space: nowrap;
  cursor: pointer;
}

.document-list__button--summary {
  background-color: var(--meeting-list-accent-color);
  color: white;
  border: none;
}

.document-list__button--summary:hover {
  background-color: var(--meeting-list-accent-color-hover);
}

.document-list__button--detailed {
  background-color: transparent;
  color: var(--meeting-list-accent-color);
  border: 1px solid currentColor;
}

.document-list__button--detailed:hover {
  background-color: rgba(68, 102, 238, 0.1);
}

.document-list__empty {
  text-align: center;
  padding: 3rem;
  color: var(--document-list-text-secondary);
  background: var(--document-list-surface-color);
  border-radius: 12px;
  border: 1px solid var(--document-list-border-color);
}

.document-list__document-preview {
  color: var(--text-secondary);
  line-height: 1.5;
  margin: 1.5rem 0;
  white-space: pre-wrap;
  padding-right: 8rem;
}

.document-list__topics {
  margin-top: 0;
}

.document-list__topics-header {
  color: var(--document-list-text-primary);
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.document-list__topics-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.document-list__topic-item {
  color: var(--text-secondary);
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: var(--background-secondary);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  position: relative;
  padding-left: 1.5rem;
}

.document-list__topic-item::before {
  content: "•";
  position: absolute;
  left: 0.75rem;
  color: var(--text-secondary);
}

.document-list__topics-expand {
  margin-top: 1rem;
  color: var(--meeting-list-accent-color);
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s ease;
}

.document-list__topics-expand:hover {
  color: var(--meeting-list-accent-color-hover);
}

@media (max-width: 1024px) {
  .document-list__container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .document-list {
    padding: 1.5rem;
  }

  .document-list__card {
    padding: 1rem;
  }

  .document-list__card-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .document-list__buttons {
    width: 100%;
  }

  .document-list__button {
    flex: 1;
    text-align: center;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  .document-list {
    padding: 1rem;
  }

  .document-list__title {
    font-size: 1.5rem;
  }
}

.document-list__importants {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.document-list__important-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.document-list__important-header {
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 600;
}

.document-list__important-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.document-list__important-item {
  color: var(--text-secondary);
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: var(--background-secondary);
  border-radius: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.document-list__important-item::before {
  content: "•";
  position: absolute;
  left: 0.75rem;
  color: var(--text-secondary);
} 