/* Base styles for sidebar */
.sidebar {
  display: flex;
  flex-direction: column;
  width: 16rem;
  height: 100vh;
  background-color: #0a192f;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

/* Logo section */
.logo {
  padding: 1.25rem 1.5rem;  /* Reduced padding */
  margin-bottom: 0.5rem;    /* Added small margin instead of border */
}

.logo-text {
  font-size: 1.75rem;      /* Slightly larger font */
  font-weight: 600;        /* Slightly reduced weight */
  color: white;
  letter-spacing: -0.5px;  /* Tighter letter spacing */
}

/* Navigation section */
.nav {
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;      /* Removed top/bottom padding */
  gap: 0.25rem;           /* Reduced gap between items */
}

.nav-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;        /* Slightly larger font size */
  font-weight: 500;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-button.active {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);  /* Added subtle border */
}

.nav-button .icon {
  margin-right: 0.875rem;  /* Adjusted spacing */
  width: 1.25rem;
  height: 1.25rem;
  stroke-width: 1.75;      /* Slightly thicker icons */
  color: white;
}

.nav-button span {
  margin-left: 0;          /* Removed extra margin */
}

/* Remove the underline animation since it's not in the reference */
.nav-button::after {
  display: none;
}

/* Responsive design */
@media (max-width: 768px) {
  .sidebar {
    width: 4rem;
    overflow: hidden;
  }

  .logo-text {
    display: none;
  }

  .nav-button span {
    display: none;
  }

  .nav-button {
    justify-content: center;
    padding: 0.75rem;
  }

  .nav-button .icon {
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .sidebar {
    display: none;
  }
}