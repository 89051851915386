:root {
  --company-text-primary: #1A1A1A;
  --company-text-secondary: #6B7280;
  --company-accent: #4466EE;
  --company-accent-hover: #3355DD;
  --company-border: #E5E7EB;
  --company-error: #DC2626;
}

.company-info {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  position: relative;
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  background: rgba(10, 25, 47, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.logo-landing {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.company-info__main {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: relative;
  z-index: 2;
}

.company-info__container {
  width: 100%;
  max-width: 480px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.company-info__title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--company-text-primary);
  margin-bottom: 0.5rem;
  text-align: center;
}

.company-info__subtitle {
  color: var(--company-text-secondary);
  font-size: 0.875rem;
  margin-bottom: 2rem;
  text-align: center;
}

.company-info__form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--company-text-primary);
}

.form-input {
  padding: 0.75rem;
  border: 1px solid var(--company-border);
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9);
}

.form-input:focus {
  outline: none;
  border-color: var(--company-accent);
  box-shadow: 0 0 0 2px rgba(68, 102, 238, 0.1);
  background-color: rgba(255, 255, 255, 1);
}

.form-input.error {
  border-color: var(--company-error);
}

select.form-input {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  padding-right: 2.5rem;
}

.error-message {
  color: var(--company-error);
  font-size: 0.75rem;
}

.submit-button {
  padding: 0.75rem;
  background-color: var(--company-accent);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 0.5rem;
}

.submit-button:hover {
  background-color: var(--company-accent-hover);
}

@media (max-width: 640px) {
  .company-info__main {
    padding: 1rem;
  }

  .company-info__container {
    padding: 1.5rem;
  }

  .header {
    padding: 1rem;
  }
}