/* Overlay to cover the entire screen */
.invite-coworker-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invite-coworker-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    overflow: hidden; /* Prevent scrolling */
    z-index: 1000; /* Ensure the modal overlay is above other content */
}

/* Modal container */
.invite-coworker-modal__container {
    position: relative;
    background-color: #ffffff;
    padding: 2.5rem;
    border-radius: 1rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    width: 90%;
    max-width: 500px;
    max-height: 90vh; /* Ensure modal doesn't exceed the viewport height */
    overflow-y: auto; /* Allow scrolling for large content */
    margin: 0 auto;
}

/* Close button */
.invite-coworker-modal__close-btn {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Title */
.invite-coworker-modal__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
}

/* Form group */
.invite-coworker-modal__form-group {
    margin-bottom: 1.5rem;
}

/* Form input */
.invite-coworker-modal__input {
    width: 100%;
    padding: 0.875rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    background-color: #f9fafb;
    font-size: 1rem;
}

/* Submit button */
.invite-coworker-modal__submit-btn {
    width: 100%;
    background-color: #007AFF;
    color: white;
    padding: 0.875rem;
    border-radius: 9999px;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.invite-coworker-modal__submit-btn:disabled {
    background-color: #6b7280;
    cursor: not-allowed;
}

/* Pending invites list */
.invite-coworker-modal__pending-list {
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
    max-height: 150px; /* Set maximum height */
    overflow-y: hidden; /* Hide the scrollbar initially */
    transition: overflow 0.3s ease;
}

/* Show scroll only when hovering over the pending invites list */
.invite-coworker-modal__pending-list:hover {
    overflow-y: auto; /* Enable scrolling when hovering */
}

/* Single pending invite item */
.invite-coworker-modal__pending-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;  /* Reduced padding */
    background-color: #f9fafb;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

/* Circular avatar for the first letter of the email */
.invite-coworker-modal__avatar {
    width: 2rem;  /* Smaller size */
    height: 2rem;
    background-color: #4caf50; /* Green background */
    border-radius: 50%;  /* Circular shape */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 1rem;  /* Smaller font */
    margin-right: 0.75rem;  /* Reduced margin */
}

/* Email and status container */
.invite-coworker-modal__pending-info {
    display: flex;
    flex-direction: column;
}

/* Pending email text */
.invite-coworker-modal__pending-email {
    font-size: 0.75rem;  /* Smaller font */
    font-weight: 500;
    color: #111827;
}

/* Pending status text */
.invite-coworker-modal__pending-status {
    font-size: 0.65rem;  /* Smaller font */
    color: #6b7280;
}

/* Error message */
.invite-coworker-modal__error {
    color: #d32f2f;
    margin-bottom: 1rem;
    text-align: center;
}

/* Subtitle for pending invites */
.invite-coworker-modal__subtitle {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.5rem;
    color: #374151;
}
